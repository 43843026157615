<template>
   <v-dialog v-model="show" max-width="390">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <v-row>
               <v-col>
                  <v-text-field
                     v-model="nombre"
                     label="Nombre"
                     required
                     :error-messages="nombreErrors"
                     @input="$v.nombre.$touch()"
                     @blur="$v.nombre.$touch()"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="3">
                  <v-switch style="width: max-content" color="accent" v-model="x64" :append-icon="x64 == true ? 'mdi-cpu-64-bit' : 'mdi-cpu-32-bit'">
                  </v-switch>
               </v-col>
            </v-row>
            <v-text-field
               v-model="link"
               label="Link"
               class="mb-4"
               required
               :error-messages="linkErrors"
               @input="$v.link.$touch()"
               @blur="$v.link.$touch()"
            ></v-text-field>
            <div class="d-flex align-center">
               <v-autocomplete
                  v-model="admin"
                  label="Usuario"
                  class="my-0 py-0"
                  :loading="search_loading_admin"
                  :search-input.sync="search_admin"
                  :items="search_items_admin"
                  item-text="nombre"
                  item-value="id"
                  auto-select-first
                  return-object
                  outlined
                  ref="autocomplete_admin"
                  persistent-hint
                  :hint="this.admin.id > 0 ? 'ID Usuario: ' + this.admin.id : ''"
               >
               </v-autocomplete>
               <v-btn
                  class="mb-7 ml-1"
                  icon
                  @click="
                     admin = {
                        id: null,
                        nombre: '',
                        username: '',
                     }
                  "
               >
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </div>
            <v-row justify="space-around">
               <v-col>
                  <v-text-field v-model="cmd" label="Comandos"></v-text-field>
               </v-col>
               <v-col cols="2" class="d-flex align-center">
                  <v-dialog transition="dialog-bottom-transition" max-width="600">
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn color="accent" icon v-bind="attrs" v-on="on"><v-icon>mdi-help</v-icon></v-btn>
                     </template>
                     <template v-slot:default="dialog">
                        <v-card>
                           <v-toolbar color="primary" elevation="1">
                              Comandos post descarga
                              <v-spacer></v-spacer>
                              <v-btn icon @click="dialog.value = false"><v-icon>mdi-close</v-icon></v-btn>
                           </v-toolbar>
                           <v-card-text class="mt-4">
                              Los comandos post descarga son instrucciones que se ejecutan al terminar la descarga y extracción del .zip que contiene
                              los archivos de actualización. Si es una carpeta, por ejemplo de Microsip, se ejecuta el comando:
                              <div class="coder">shortcut("APP_PATH", "SHORTCUT_NAME");</div>
                              para crear un acceso directo en el escritorio.
                              <div class="coder">shortcut("2022/Microsip.exe", "Microsip 2022");</div>
                              Todas las instrucciones deben terminar con
                              <div class="coder coder-inline">;</div>
                              Se puede utilizar el comando:
                              <div class="coder">exec("APP_PATH");</div>
                              para ejecutar una aplicación o instalador.
                              <div class="coder">exec("pegaso_mensajeria_setup.exe");</div>
                              Para terminar un proceso antes de extraer el archivo .zip se ejecuta el comando:
                              <div class="coder">kill("PROCESS_NAME");</div>
                           </v-card-text>
                        </v-card>
                     </template>
                  </v-dialog>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(50),
      },
      link: {
         required,
         maxLength: maxLength(360),
      },
   },
   data: () => {
      return {
         id_update: 0,
         nombre: "",
         link: "",
         cmd: "",
         x64: false,
         show: false,
         isloading: false,

         search_admin: "",
         search_loading_admin: false,
         search_items_admin: [],
         admin: {
            id: NaN,
            nombre: "",
            username: "",
         },
      };
   },
   computed: {
      title: function () {
         if (this.id_update === -1) {
            return "Agregar actualización";
         } else {
            return "Editar actualización";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("Requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 50 caracteres");
         return errors;
      },
      linkErrors() {
         const errors = [];
         if (!this.$v.link.$dirty) return errors;
         !this.$v.link.required && errors.push("Requerido");
         !this.$v.link.maxLength && errors.push("Debe contener maximo 360 caracteres");
         return errors;
      },
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (this.id_update >= 0) {
                  res = await this.$store.dispatch("updateUpdates", {
                     id: this.id_update,
                     nombre: this.nombre,
                     link: this.link,
                     x64: this.x64 == true ? "S" : "N",
                     id_usuario: this.admin.id == -1 ? "" : this.admin.id,
                     cmd: this.cmd,
                  });
               } else {
                  res = await this.$store.dispatch("insertUpdate", {
                     nombre: this.nombre,
                     link: this.link,
                     x64: this.x64 == true ? "S" : "N",
                     id_usuario: isNaN(this.admin.id) ? "" : this.admin.id,
                     cmd: this.cmd,
                  });
               }
               if (res == true) {
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
            this.$v.$reset();
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$v.$reset();
         this.$emit("result_cancel");
      },
      Show(update) {
         this.$v.$reset();
         this.id_update = update.id_update;
         this.nombre = update.nombre;
         this.link = update.link;
         this.x64 = update.x64 == "S" ? true : false;
         this.admin.id = update.id_usuario;
         this.cmd = update.cmd;
         this.show = true;
      },
      get_search_admin: async function () {
         try {
            if (this.search_admin) {
               if (this.search_admin.trim().length > 0) {
                  this.search_loading_admin = true;
                  let res = await this.$store.dispatch("getFastAdmins", {
                     search: this.search_admin,
                  });
                  if (res == true) {
                     this.search_items_admin = this.$store.state.fast_admins;
                  } else {
                     throw res;
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading_admin = false;
         }
      },
   },
   watch: {
      search_admin: function () {
         this.get_search_admin();
      },
   },
};
</script>

<style scoped>
.coder {
   font-family: "consolas";
   font-weight: bold;
   margin: 8px;
   padding: 8px;
   color: black;
   background-color: white;
   border-radius: 5px;
}

.coder-inline {
   display: inline-block;
   margin: 3px;
   padding: 3px;
}
</style>
