<template>
   <Updates/>
</template>

<script>
import Updates from '../components/Updates/Updates.vue'

export default {
   name: "Vendedores",
   components:{
      Updates
   }
};
</script>
