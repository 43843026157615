<template>
   <div>
      <Update ref="update" @result_ok="$refs.ok_noti.Show(), initialize()" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog message="¿Eliminar actualización?" ref="delete" @result_ok="deleteItemConfirm" />
      <v-data-table :headers="headers" :items="rows" class="ma-3">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn color="primary" @click="$refs.update.Show(defaultItem)"><v-icon left>mdi-plus-circle</v-icon>Nueva actualización</v-btn>
               <v-btn color="accent" @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.x64`]="{ item }">
            <v-icon>{{ item.x64 == "S" ? "mdi-cpu-64-bit" : "mdi-cpu-32-bit" }}</v-icon>
         </template>
         <template v-slot:[`item.id_usuario`]="{ item }">
            <div v-if="item.id_usuario === -1">
               <v-icon small>mdi-account-group</v-icon>
            </div>
            <div v-else><v-icon small left>mdi-account</v-icon>{{ item.id_usuario }}</div>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="doDeleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Dialog from "../Dialog.vue";
import Notification from "../Notification.vue";
import Update from "./Updates/Update.vue";

export default {
   components: {
      Dialog,
      Notification,
      Update,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_update" },
         { text: "Nombre", value: "nombre" },
         { text: "Link", value: "link" },
         { text: "Comando", value: "cmd" },
         { text: "User", value: "id_usuario" },
         { text: "Arch", value: "x64" },
         { text: "Actions", value: "actions", sortable: false },
      ],
      rows: [],
      deleteItem: {},
      defaultItem: {
         id_update: -1,
         nombre: "",
         link: "",
         cmd: "",
         x64: "N",
      },
   }),
   created() {
      this.initialize();
   },
   methods: {
      async initialize() {
         this.rows = this.$store.state.updates;
      },
      editItem(item) {
         this.$refs.update.Show(item);
      },
      doDeleteItem(item) {
         this.deleteItem = item;
         this.$refs.delete.Show();
      },
      async deleteItemConfirm() {
         try {
            let res = await this.$store.dispatch("deleteUpdate", { id: this.deleteItem.id_update });
            if (res == true) {
               this.initialize();
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
   },
};
</script>
